<template>
	<div class="product" :class="{custom:isCustom}">
		<section class="section--page pb-5 mt-md-6">

			<component v-if="customComponent" :is="customComponent"
					   :adding="isLoading"
					   @info="onInfo(true)"
					   @basket-add="onAddToBasket" />
			<product-with-content v-else-if="withContent"
								  :content="content"
								  :adding="isLoading"
								  @info="onInfo(true)"
								  @custom="onCustomFlavours"
								  @basket-add="onAddToBasket" />
			<product-without-content v-else
									 :adding="isLoading"
									 @info="onInfo(true)"
									 @basket-add="onAddToBasket" />

			<product-info v-if="withInfo"
						  id="product-info"
						  :custom-flavours="customFlavours"
						  :is-open="isProductInfoOpen" @close="onInfo(false)" />

		</section>

		<!--<product-content v-if="withContent" id="product-content"
				 :item="item"
				 :isCustom="isCustom"
				 :flavours="flavours"
				 :selected="selected"
				 :types="types"
				 :loading="isLoading"
				 @basket-add="onAddToBasket"/>-->

		<section v-if="isWindowMD" class="section--image product__footer" :style="footerBannerStyle"/>
		<section v-else class="section--image product__footer" :style="footerBannerMobileStyle"/>
	</div>
</template>

<script>
import CategoryHeader from "@/views/Category/category-header";
import Divider from "@/components/divider";
import BtnPrimary from "@/components/btn-primary";
import ProductWithContent from "@/views/Product/product-with-content";
import ProductWithoutContent from "@/views/Product/product-without-content";
import Product1Kilo from "@/views/Product/product-1kilo";
import ProductContent from "@/views/Product/product-content";
import ProductInfo from "@/views/Product/product-info";
import FlavourTypes from "@/components/flavour-types";
import {nf} from '@/utils/string';
import {scrollTo} from '@/utils/utils';

export default {
	name: "product",
	components: {
		CategoryHeader,
		Divider,
		BtnPrimary,
		//FlavoursLaurent,
		ProductContent,
		ProductInfo,
		ProductWithContent,
		ProductWithoutContent,
		Product1Kilo
	},
	props: ["code", "sectionCode", "type"],
	data() {
		return {
			isLoading: false,
			isCustom: false,
			isProductInfoOpen: false,
			customFlavours: null,
		}
	},
	computed: {
		isWindowXS() {
			return this.$store.getters.isWindowXS;
		},
		isWindowSM() {
			return this.$store.getters.isWindowSM;
		},
		isWindowMD() {
			return this.$store.getters.isWindowMD;
		},
		isWindowLG() {
			return this.$store.getters.isWindowLG;
		},
		page() {
			return this.$store.state.page.ProductController;
		},
		item() {
			return this.page.item;
		},
		withContent() {
			if (!this.item.AVAILABLE_FLAVOUR_IDS) {
				return this.item.FLAVOUR_IDS;
			} else return this.item.AVAILABLE_FLAVOUR_IDS;
		},
		withInfo() {
			return this.item.FLAVOUR_IDS?.length || this.item.ENERGY_KJ;
		},
		customComponent() {
			return this.item.TEMPLATE === "1 KILO" ? Product1Kilo : null;
		},

		content() {
			if (this.$route.query.flavourId) {
				return [this.$route.query.flavourId];
			}
			return null;
		},
		footerBannerStyle() {
			if (this.page.bottomBanners) {
				const r = Math.floor(Math.random() * this.page.bottomBanners.length);
				const url = this.page.bottomBanners[r];
				return {'background-image': 'url("' + url + '")'}
			}
			return null;
		},
		footerBannerMobileStyle() {
			if (this.page.bottomBannersMobile) {
				const r = Math.floor(Math.random() * this.page.bottomBannersMobile.length);
				const url = this.page.bottomBannersMobile[r];
				return {'background-image': 'url("' + url + '")'}
			}
			return null;
		}
	},
	methods: {
		onInfo(open) {
			this.isProductInfoOpen = open;
			if (this.isProductInfoOpen) scrollTo("product-info", -160);
			else {
				scrollTo("app", 0);
			}
		},
		onCustomFlavours(flavours) {
			if (flavours?.length) this.customFlavours = flavours;
			else this.customFlavours = null;
		},
		onAddToBasket(item, flavourIds, offerId) {

			console.log('item', item)
			console.log('flavourIds', flavourIds)
			console.log('offerId', offerId)

			this.isLoading = true;
			this.$store.dispatch('post', {
				action: 'BasketAddController',
				params: {
					productId: this.item.ID,
					flavourIds,
					offerId,
					amount: 1
				},
			}).then((result) => {
				this.$store.commit('setSession', result);
			}).catch((error) => {
				this.error = error?.message;
				//this.isSnackError = true;
			}).finally(() => {
				this.isLoading = false;
			});
		},
	}
}
</script>

<style lang="scss">
.product {
	&__footer {
		margin-top: 75px;
		@include up($md) {
			margin-top: 140px;
		}
		//background-image: url("~@/assets/box/nuts.jpg");
		background-image: url("~@/assets/box/bg-lg.jpg");

		// пауза для появления после загрузки продукта
		// todo добавить @loaded на картинку продукта
		animation: animFooterImage 300ms forwards;
		animation-delay: 750ms;
		opacity: 0;
		//transform-origin: right 40px;
	}

	@keyframes animFooterImage {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
}
</style>
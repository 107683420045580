<template>
	<div class="product-info">
		<transition name="fade">
			<v-container v-if="isOpen">
				<h3 class="text-center mt-12">{{item.NAME}}</h3>
				<h5 class="text-center mb-12">О продукте</h5>
				<v-row>
					<v-col cols="6" sm="3" class="product-info__grid-block align-sm-start">
						<div class="h6">Срок годности</div>
						<div class="h6" v-html="shelfLife"></div>
					</v-col>
					<v-col cols="6" sm="3" class="product-info__grid-block pr-md-5">
						<div class="h6">Вес Нетто</div>
						<div class="h6" v-html="netWeight"></div>
					</v-col>
					<v-col cols="6" sm="3" class="product-info__grid-block align-sm-start pl-md-9 pl-lg-15">
						<div class="h6">Темп. хранения</div>
						<div class="h6" v-html="temperature"></div>
					</v-col>
<!--					<v-col cols="6" sm="3" class="product-info__grid-block align-sm-end">
						<div class="h6">Price per kg</div>
						<div class="h6" v-html="pricePerKg"></div>
					</v-col>-->
					<v-col cols="12" sm="6" class="product-info__grid-block align-sm-start text-sm-left pr-sm-4">
						<div class="h6">Энергетическая ценность в 100 г</div>
						<p class="size2">{{nutrition}}</p>
					</v-col>
					<v-col cols="12"
						   sm="6"
						   class="product-info__grid-block align-sm-start text-sm-left pl-md-9 pl-lg-15">
						<div class="h6">Аллергены</div>
						<p class="size2" v-html="allergens"></p>
					</v-col>
					<v-col cols="12" class="product-info__grid-block align-sm-start text-sm-left">
						<div class="h6">Состав</div>
						<p class="size2" v-html="ingredients"></p>
					</v-col>
				</v-row>

				<div class="text-center">
								<span class="close a size2--u color-black" @click="$emit('close')">
									Закрыть
									<svgicon name="cross"/>
								</span>
				</div>
			</v-container>
		</transition>
	</div>
</template>

<script>
	import {nf} from "@/utils/string";

	export default {
		name: "product-info",
		components: {},
		props: ["isOpen"],
		data() {
			return {}
		},
		computed: {
			page() {
				return this.$store.state.page.ProductController;
			},
			item() {
				return this.page.item;
			},
			flavours() {
				return this.page.flavours?.filter(el => this.item.FLAVOUR_IDS?.some(flavourId => flavourId === el.ID));
			},
			shelfLife() {
				return this.flavours[0].SHELF_LIFE;
			},
			netWeight() {
				return this.flavours.reduce((total, el) => {
					const w = Number(el.NET_WEIGHT?.replace(/\D+/, ""));
					if (isNaN(w)) return total;
					return total + w;
				}, 0) + " г";
			},
			temperature() {
				return this.flavours[0].TEMPERATURE;
			},
			pricePerKg() {
				return this.flavours[0].PRICE_PER_KG;
			},
			nutrition() {
				let energy = 0, kcal = 0, fats = 0, carbohydrates = 0, proteins = 0;
				this.flavours.forEach(el => {
					if (el.ENERGY_KJ && el.KCAL) {
						energy += Number(el.ENERGY_KJ);
						kcal += Number(el.KCAL);
					}
					if (el.FATS) fats += Number(el.FATS);
					if (el.CARBOHYDRATES) carbohydrates += Number(el.CARBOHYDRATES);
					if (el.PROTEINS) proteins += Number(el.PROTEINS);
				});

				const texts = [];
				if (energy && kcal) texts.push( energy + " кДж/" + kcal + " ккал");
				if (fats) texts.push("жиры " + nf(fats, 2) + " г");
				if (carbohydrates) texts.push("углеводы " + nf(carbohydrates, 2) + " г");
				if (proteins) texts.push("белки " + nf(proteins, 2) + " г");
				return texts.join(", ");
			},
			allergens() {
				return this.flavours[0].ALLERGENS;
			},
			ingredients() {
				const i = this.flavours[0].INGREDIENTS;
				return i.replaceAll(/(milk|soy|lactose|hazelnuts|hazelnut|wheat|barley|ginger|peanuts|peanut|walnut|dairy)/isg, "<b>$1</b>");
			}
		},
		methods: {},
	}
</script>

<style lang="scss">
	.product-info {
		max-width: 1050px;
		margin: 0 auto;

		&__grid {
			&-block {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				padding: 16px 0 !important;
				text-align: center;
				align-items: center;
				border-bottom: 1px solid $gray-light;
			}
		}

		.close {
			display: inline-flex;
			margin-top: 40px;
			align-items: center;
			svg {
				width: 12px;
				height: 12px;
				margin-left: 8px;
				stroke: $black;
				/*@include up($md) {
					width: 12px;
					height: 12px;
				}*/
			}

			/*&:hover {
				stroke: $black;
			}*/
		}
	}
</style>
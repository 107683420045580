<template>
	<div class="d-none d-md-flex justify-center category-header">
		<div class="d-flex align-center category-header__items">
			<template v-for="(item,index) of items">
				<router-link :to="{name:'category',params:{code:item.CODE}}" class="size2--u">{{item.UF_LIST_NAME || item.NAME}}</router-link>
				<span v-if="index+1<items.length" class="dot"></span>
			</template>
		</div>
	</div>
</template>

<script>
    export default {
        name: "category-header",
		props: ["items"],
    }
</script>

<style lang="scss">
	.category-header {
		//width: 512px;

		position: fixed;
		z-index: 49;	// должен быть выше анимации
		left: 0;
		right: 0;
		margin: 0 auto;
		background: $header-white;

		&__items {
			padding-bottom: 7px;
			border-bottom: 1px solid $primary-100;
			flex-wrap: wrap;
			max-width: 800px;
			justify-content: center;
			@include up($lg) {
				max-width: 1260px;
			}
			@include up($xl) {
				flex-wrap: nowrap;
				max-width: unset;
			}

			a {
				display: inline-block;
				white-space: nowrap;
				line-height: 24px;
				/*margin: 0 0 7px 0;

				@include up($lg) {
					margin: 0;
				}*/

				&:not(.router-link-active) {
					color: $black !important;

					//font-weight: 300;
				}
			}
		}
	}
</style>
<template>
	<div class="anim-fade-in flavour-list-mini pt-2 pt-md-6">
		<div v-if="swipable" class="swiper flavour-list-mini__swiper">
			<div class="swiper-wrapper">
				<div class="swiper-slide flavour-list-mini__item py-4 shown"
					 v-for="(item, index) in items"
					 :key="index">
					<div class="d-flex flex-column justify-start align-center">
						<div class="flavour-list-mini__item-img pointer" @click="$emit('click', item)">
							<v-img :src="item.PREVIEW_PICTURE"
								   :alt="item.NAME"
								   :class="{image:item.DETAIL_PICTURE}"
								   @load="onLoaded(item)"/>
							<!--<svgicon name="plus" :original="true" class="plus"/>-->
							<v-img v-if="item.DETAIL_PICTURE" :src="item.DETAIL_PICTURE" :alt="item.name"
								   class="cut"/>
						</div>
						<!--<div class="size2 mt-4 text-center flavour-list-mini__item-name" v-html="nameHtml(item)" @click="$emit('click', item)"></div>-->
						<div class="flavour-list-mini__item-text text-center">
							<div class="h6 mt-4 flavour-list-mini__item-name"
								 v-html="nameWithoutChocoHtml(item)"
								 @click="$emit('click', item)"></div>
							<div class="size3 mt-1 flavour-list__choco" v-html="subnameHtml(item)"></div>
							<router-link :to="toFlavour(item)"
										 class="size3 mt-2 flavour-list-mini__about-link">о конфете
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<v-row v-else>
			<v-col v-for="(item, index) in items" :key="index"
				   cols="6" sm="3" md="4" lg="3"
				   class="flavour-list-mini__item"
				   :class="{loaded:item.loaded,shown:item.shown}">
				<div class="d-flex flex-column justify-start align-center">
					<div class="flavour-list-mini__item-img pointer" @click="$emit('click', item)">
						<svgicon v-if="!item.shown" name="logo" class="logo"/>
						<v-img :src="item.PREVIEW_PICTURE"
							   :alt="item.NAME"
							   :class="{image:item.DETAIL_PICTURE}"
							   @load="onLoaded(item)"/>
						<!--<svgicon name="plus" :original="true" class="plus"/>-->
						<v-img v-if="item.DETAIL_PICTURE" :src="item.DETAIL_PICTURE" :alt="item.name"
							   class="cut"/>
					</div>
					<!--<div class="size2 mt-4 text-center flavour-list-mini__item-name" v-html="nameHtml(item)" @click="$emit('click', item)"></div>-->
					<div class="flavour-list-mini__item-text text-center">
						<div class="h6 mt-4 flavour-list-mini__item-name"
							 v-html="nameWithoutChocoHtml(item)"
							 @click="$emit('click', item)"></div>
						<div class="size3 mt-1 flavour-list__choco" v-html="subnameHtml(item)"></div>
						<div v-if="item.count>1">x{{item.count}}</div>

						<router-link v-if="$route.params.sectionCode === 'truffles'" :to="toFlavour(item)"
									 class="size3 flavour-list-mini__about-link d-none">о вкусе
						</router-link>
						<router-link v-else-if="$route.params.sectionCode === 'makaroni'" :to="toFlavour(item)"
									 class="size3 flavour-list-mini__about-link">о вкусе
						</router-link>
						<router-link v-else :to="toFlavour(item)"
									 class="size3 flavour-list-mini__about-link">о конфете
						</router-link>
					</div>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import Vue from 'vue';
import {Swiper} from 'swiper';
import 'swiper/swiper.min.css';

let swiper;

export default {
	name: "flavour-list-mini",
	props: {
		items: {
			type: Array,
		},
		swipable: {
			type: Boolean,
			default: false
		},
		selectable: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isLoaded: false,
			countLoaded: 0,
			//itemsCopy: null,
			sortedItems: null
		}
	},
	watch: {
		items() {
			this.initSwiper();
		},
		swipable(val, old) {
			if (val) this.initSwiper(true);
		}
	},
	computed: {
		isWindowXS() {
			return this.$store.getters.isWindowXS;
		},
	},
	methods: {
		toFlavour(item) {
			return {
				name: 'flavour',
				params: {
					code: item.CODE
				},
				query: {
					back: this.$route.path
				}
			};
		},
		initSwiper(forceReinit) {
			if (!this.swipable) return;
			setTimeout(() => {
				if (!swiper || forceReinit) {
					swiper = new Swiper('.flavour-list-mini__swiper', {
						//freeMode: true,
						slidesPerView: 2.5,
						//spaceBetween: 10,
						breakpoints: {
							320: {
								slidesPerView: 2.5,
								//spaceBetween: 10
							},
							480: {
								slidesPerView: 3.5,
								//spaceBetween: 20
							},
							640: {
								slidesPerView: 4.5,
								//spaceBetween: 20
							},
							800: {
								slidesPerView: 5.5,
								//spaceBetween: 20
							},
							960: {
								slidesPerView: 6.5,
								//spaceBetween: 20
							}
						}
					});
				} else swiper.update();

			}, 150);
		},
		nameHtml(item) {
			//return item.name.substr(0, 1).toUpperCase() + item.name.substr(1).toLowerCase();
			return item.NAME.replace(/\n/, "<br/>");
		},
		nameWithoutChocoHtml(item) {
			//return item.name.substr(0, 1).toUpperCase() + item.name.substr(1).toLowerCase();
			return item.NAME.replace(/(^.+?)(в )?(горьком|молочном).+$/is, "$1");
		},
		subnameHtml(item) {
			if (item.SUBNAME) return item.SUBNAME;
			//return item.choco.substr(0, 1).toUpperCase() + item.choco.substr(1).toLowerCase();
			let choco = null;
			if (item.NAME.match(/.+?filled with .+$/)) {
				choco = item.NAME.replace(/.+?(filled with.+$)/is, "$1");
				choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
			} else if (item.NAME.match(/.+?(в )?(горьком|молочном).+$/)) {
				choco = item.NAME.replace(/.+?((в )?(горьком|молочном).+$)/is, "$1");
				choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
			} else if (item.NAME.match(/\d+ g$/)) {
				// todo make this related to item.WEIGHT
				choco = item.NAME.replace(/.+?(\d+ g$)/is, "$1");
			} else if (item.NAME.match(/\d+ pcs$/)) {
				choco = item.NAME.replace(/.+?(\d+ pcs$)/is, "$1");
				if (item.WEIGHT) choco += " / " + item.WEIGHT + " g";
			}

			return choco;
		},
		choco(item) {
			//return item.choco.substr(0, 1).toUpperCase() + item.choco.substr(1).toLowerCase();
			return item.NAME.replace(/.+?((в )?(горьком|молочном).+$)/is, "$1");
		},
		setOver(item, v) {
			Vue.set(item, "isOver", v);
		},
		onLoaded(item) {
			//console.log("Loaded: ", item);
			// показываем конфетку чуть позже, чем загрузилась - это делается дл лучше визуального эффекта с учестов transition
			setTimeout(() => Vue.set(item, "loaded", true), 300);
			setTimeout(() => Vue.set(item, "shown", true), 500);
			this.countLoaded++;
			if (this.countLoaded >= this.items.length) this.isLoaded = true;
		}
	},
	mounted() {
		this.countLoaded = 0;
	}
}
</script>

<style lang="scss">
.flavour-list-mini {

	&__about-link {
		color: $default-text-color !important;
		@include transition();

		@include up($md) {
			opacity: 0;
		}
	}

	&__item {
		@include transition();

		&.loaded {
			svg {
				opacity: 0;
			}
		}

		&.shown {
			.v-image {
				opacity: 1;
			}

			.flavour-list-mini__item-name {
				color: $golden;
			}
		}

		&:hover {
			.flavour-list-mini__about-link {
				opacity: 1;

				&:hover {
					color: $golden !important;
				}
			}
		}

		&-img {
			position: relative;
			width: 70px;
			height: 60px;
			display: flex;
			justify-content: center;
			align-content: center;

			@include up($md) {
				width: 100px;
				height: 80px;
			}

			svg {
				position: absolute;
				z-index: 1;
				@include transition();

				&.logo {
					top: 50%;
					left: 50%;
					width: 64px;
					height: 64px;
					transform: translateX(-50%) translateY(-50%);
					fill: $golden-light;
				}

				/*&.plus {
					top: 0%;
					left: 100%;
					width: 24px;
					height: 24px;
					transform: translateX(-50%) translateY(-50%);
					!*width: 52px;
					height: 52px;*!
					//fill: $white;
				}*/
			}

			.v-image {
				max-width: 60px;
				height: 60px;

				@include up($md) {
					max-width: 80px;
					height: 80px;
				}

				&.cut {
					position: absolute;
					z-index: 1;
					opacity: 0;
				}
			}

			&:hover {
				.cut {
					opacity: 1;
				}

				.image {
					opacity: 0;
				}
			}
		}

		&-name {
			/*opacity: 0;
			transform: translateY(5px);*/
			color: $golden-light;
			//max-width: 200px;
			//white-space: nowrap;
			max-width: 240px;
			cursor: pointer;
			@include transition();

			&:hover {
				opacity: 0.6;
			}
		}
	}

	.v-image {
		width: 100%;
		opacity: 0;
		@include transition();
	}

	&__choco {
		color: $black;
		//max-width: 200px;
		text-transform: lowercase;

		@include up($md) {
			padding-top: 1px;
			font-weight: 300;
			color: $golden;
			//text-transform: lowercase;
		}
	}

	&__swiper {
		margin: 0 -$container-padding-x;
	}
}


</style>
<template>
    <div class="product-1kilo" :class="{custom:isCustom}">
        <v-container>
            <v-row class="align-md-top justify-space-between">
                <v-col cols="12" class="text-center relative">

                    <div class="mb-2 text-center">
                        <h1 v-html="nameHtml"></h1>
                        <h5 v-html="chocoHtml" class="mt-2"></h5>
                    </div>


                    <div class="product-1kilo__sticky">
                        <!--<div class="product-1kilo__image" @click="onImageClick">

                            <v-img :src="photo0"
                                   class="closed"
                                   contain
                                   :alt="item.NAME"/>
                            <v-img v-if="photo1"
                                   :src="photo1"
                                   class="open abs"
                                   contain
                                   :alt="item.NAME"/>
                        </div>-->

                        <div class="product-1kilo__image">

                            <div class="product-1kilo__frame mt-8 mb-8">
                                <v-img :src="photo0"
                                       class="spacer"
                                       :class="boxClass"
                                       contain
                                       :alt="item.NAME"
                                       v-if="!isCustom"
                                />

                                <transition name="fade" mode="out-in">
                                    <div v-if="isCustom" class="box" id="box"
                                         :class="boxClass" key="box1" :style="customizationStyle">
                                        <div v-for="(index) in amount" class="cell" :key="'cell'+index">

                                            <v-img v-if="cells[index-1]" :key="index" class="anim-fade-in"
                                                   :src="cells[index-1].PREVIEW_PICTURE" contain
                                                   @click="onBoxItemClick(index-1)"/>

                                            <transition name="fade-quick">
                                                <svgicon v-if="!cells[index-1]" name="plus" class="abs"
                                                         :original="true"
                                                         @click="onEmptyBoxItemClick(index-1)"/>
                                            </transition>
                                        </div>
                                    </div>
                                    <div v-else class="images" key="box2">
                                        <v-img :src="photo0" class="abs"
                                               :class="boxClass"
                                               contain
                                               :alt="item.NAME"/>
                                        <v-img v-if="photo1"
                                               :src="photo1"
                                               class="open abs"
                                               :class="boxClass"
                                               contain
                                               :alt="item.NAME"/>
                                    </div>
                                </transition>

                            </div>
                        </div>

                        <div class="product-1kilo__tools" :class="{open:isCustom}">
                            <a class="size2 mr-4" :class="{disabled:!withClearBtn}" @click="onClear">Очистить</a>
                            <a class="size2 ml-4" @click="onRandomize">Произвольно</a>
                        </div>

                        <v-row class="justify-center align-center mt-4 mt-md-8">
                            <v-col cols="12" sm="3" class="text-sm-right">
                                <div class="d-sm-none h5 color-black">{{item.PRICE | price }} ₽</div>
                                <div class="d-none d-sm-block h3 color-black">{{item.PRICE | price }} ₽</div>
                            </v-col>
                            <v-col cols="12" sm="3" >
                                <transition name="fade-quick" mode="out-in">
                                    <btn-primary v-if="isItemInBasket"
                                                 :class="isWindowLG?'large':null"
                                                 label="В корзине" @click="$router.push({name:'basket'})"/>
                                    <btn-primary v-else
                                                 :class="isWindowLG?'large':null"
                                                 :label="isWindowLG?'В корзину':'В корзину'"
                                                 :loading="adding"
                                                 @click="onAddToBasket"/>
                                </transition>
                            </v-col>
                            <v-col cols="12" sm="3" class="text-sm-left">
                                <div class="d-none d-sm-block">
                                    <!--<div><router-link :to="flavourRoute" class="size2">About the product</router-link></div>-->
                                    <div class="product-1kilo__more">
								<span class="a size2--u color-black" @click="$emit('info')">
									Подробнее
									<svgicon name="arrow-right"/>
								</span>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>

                    </div>

                </v-col>
                <v-col cols="12" class="pt-4 pt-md-8">

                    <div class="d-flex product-1kilo__sets"
                         :class="{single:!withCustomization}">
                        <!--:to="{name:'product-flavours', params:{code:item.CODE, sectionCode:sectionCode, type:'custom'}}"-->
                        <a v-if="withCustomization"
                           @click="onCustomize(true)"
                           :class="{selected:isCustom}">
                            <div class="product-1kilo__set-image">
                                <v-img src="@/assets/box/your.svg" alt="Your choice"/>
                            </div>
                            <div class="ml-4 mr-6">
                                <h5>Ваш<br class="d-inline d-sm-none"/> вариант</h5>
                                <p class="size2 d-none d-lg-block">Выберите Ваши вкусы</p>
                            </div>
                        </a>
                        <!--:to="{name:'product-flavours', params:{code:item.CODE, sectionCode:sectionCode, type:'laurent'}}"-->
                        <a @click="onCustomize(false)"
                           :class="{selected:!isCustom}">
                            <div class="product-1kilo__set-image">
                                <v-img src="@/assets/box/laurent.svg" alt="Laurent Moreno"/>
                            </div>
                            <div class="ml-4">
                                <h5>Выбор Лорана Морено<br class="d-inline d-lg-none"/></h5>
                                <p class="size2 d-none d-lg-block">Рекомендуемый набор для этой коробки</p>
                            </div>
                        </a>


                    </div>

                    <flavour-types :flavours="flavours"
                                   :types="types"
                                   :swipable="isXS&&isCustom"
                                   class="mt-4 mt-sm-6 mt-md-10"
                                   @click="onFlavourClick"/>

                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue from "vue";
import BtnPrimary from "@/components/btn-primary";
//import ProductContent from "@/views/Product/product-content";
import FlavourTypes from "@/components/flavour-types";
import {nf} from '@/utils/string';
import {scrollTo} from '@/utils/utils';
import BtnLink from "../../components/btn-link";
import vp from "@/mixins/vp";

export default {
    name: "product-1kilo",
    mixins: [vp],
    components: {
        BtnLink,
        BtnPrimary,
        //FlavoursLaurent,
        //ProductContent,
        FlavourTypes
    },
    props: ["adding", "content"],
    data() {
        return {
            amount: 0,
            cells: null,
            isCustom: false,
            isContentOpen: false,
        }
    },
    computed: {
        isWindowXS() {
            return this.$store.getters.isWindowXS;
        },
        isWindowSM() {
            return this.$store.getters.isWindowSM;
        },
        isWindowMD() {
            return this.$store.getters.isWindowMD;
        },
        isWindowLG() {
            return this.$store.getters.isWindowLG;
        },
        page() {
            return this.$store.state.page.ProductController;
        },
        flavourRoute() {
            let anchor = "";
            if (this.section.UF_FLAVOUR_SECTION_IDS) anchor = "#section" + this.section.UF_FLAVOUR_SECTION_IDS[0];

            // todo fix the routng with named route and anchors/hashes
            return {path: '/' + anchor};
        },
        boxClass() {
            return "box" + this.item.BOX_SIZE + (this.photo1 ? " closed" : "");
        },
        categories() {
            return this.$store.state.common.sections;
        },
        item() {
            return this.page.item;
        },
        section() {
            return this.page.section;
        },
        isItemInBasket() {
            return this.$store.state.session?.basket?.find(el => Number(el.PRODUCT_ID) === Number(this.item.ID));
        },
        priceText() {
            return nf(this.item.PRICE, 2);
        },
        withInfo() {
            return this.item.FLAVOUR_IDS?.length || this.item.ENERGY_KJ;
        },
        withCustomization() {
            return this.item.BOX_SIZE;
        },
        flavours() {
            if (this.isCustom) return this.page.flavours;

            if (this.item.CONTENT_JSON) {
                // todo other types of content
                if (this.item.CONTENT_JSON.flavours) {
                    const flavours = [];
                    this.item.CONTENT_JSON.flavours.forEach(el => {
                        const id = typeof el === "object" ? Number(el.id) : Number(el);
                        const f = this.page.flavours?.find(fl => Number(fl.ID) === Number(id));
                        if (!f) return;

                        /*if ( id===294) Vue.set(f, "style", {
                            width: "120px",
                            //"max-width": "110px",
                            //position: "absolute",
                            height: "120px",
                            //margin: "-10px"
                        });*/

                        if (typeof el === "object") {
                            if (el.style) Vue.set(f, "style", el.style);
                            if (el.cols) Vue.set(f, "cols", el.cols);
                            /*const amount = Number(el.amount);
                            if (amount > 0) {
                                for (let i = 1; i <= amount; i++) {
                                    flavours.push(f);
                                }
                            }*/

                        }
                        flavours.push(f);
                    });
                    return flavours;
                }
            }

            return this.page.flavours?.filter(el => this.item.FLAVOUR_IDS?.some(flavourId => flavourId === el.ID));
        },
        collections() {
            return this.page.collections;
        },
        types() {
            if (this.isCustom) return this.page.types;
            return null;
        },
        nameHtml() {
            const item = this.item;
            //return item.name.substr(0, 1).toUpperCase() + item.name.substr(1).toLowerCase();
            let name = item.NAME;
            name = name.replace(/(^.+?)(in )?(dark|milk).+$/is, "$1");
            name = name.replace(/(^.+?)filled with .+$/is, "$1");
            name = name.replace(/(^.+?)\d+ g$/is, "$1");
            name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1");
            /*if ( name.match(/(^.+?)(\d+ pcs)$/is) ) {
                name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1<br>$2");
                if ( item.WEIGHT ) name += " / "+item.WEIGHT + " g";
            }
            else if ( item.WEIGHT ) name += "<br>"+item.WEIGHT + " g";*/

            return name;
        },
        chocoHtml() {
            const item = this.item;
            //return item.choco.substr(0, 1).toUpperCase() + item.choco.substr(1).toLowerCase();
            let choco = null;
            if (item.NAME.match(/.+?filled with .+$/)) {
                choco = item.NAME.replace(/.+?(filled with.+$)/is, "$1");
                choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
            } else if (item.NAME.match(/.+?(in )?(dark|milk).+$/)) {
                choco = item.NAME.replace(/.+?((in )?(dark|milk).+$)/is, "$1");
                choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
            } else if (item.NAME.match(/\d+ g$/)) {
                // todo make this related to item.WEIGHT
                choco = item.NAME.replace(/.+?(\d+ g$)/is, "$1");
            } else if (item.NAME.match(/\d+ pcs$/)) {
                choco = item.NAME.replace(/.+?(\d+ pcs$)/is, "$1");
                if (item.WEIGHT) choco += " / " + item.WEIGHT + " g";
            }

            return choco;
        },
        withClearBtn() {
            const anyCandyIndex = this.cells?.findIndex(el => !!el);
            return anyCandyIndex !== -1;
        },
        customizationStyle() {
            if (this.item.CUSTOMIZATION_BG) return {
                "background-image": "url('" + this.item.CUSTOMIZATION_BG + "')",
                "background-repeat": "no-repeat",
                "background-size": "contain",
            };
            return null;
        },
        photo0() {
            //return this.item.PREVIEW_PICTURE;
            if (this.item.PHOTOS && this.item.PHOTOS[0]) return this.item.PREVIEW_PICTURE;
            //return this.item.DETAIL_PICTURE;
        },
        photo1() {
            //return this.item.DETAIL_PICTURE;
            //if ( this.item.DETAIL_PICTURE ) return null;
            return this.item.PHOTOS && this.item.PHOTOS[1];
        },
    },
    methods: {
        onImageClick() {
            if (this.isMD) return;

            if (this.item.PHOTOS && this.item.PHOTOS[0] && this.item.PHOTOS[1]) {
                const temp = this.item.PHOTOS.shift();
                this.item.PHOTOS.push(temp);
            }
        },
        onAddToBasket() {
            this.$emit("basket-add");
        },
        onBoxItemClick(index) {
            //index is 1-based
            this.cells.splice(index, 1, null);
        },
        onEmptyBoxItemClick(index) {
            // fill with random
            this.cells.splice(index, 1, this.randomItem());
        },
        onFlavourClick(item) {
            if (this.isCustom) {
                if (this.isXS) scrollTo("box", -100);
                const emptyCellIndex = this.cells.findIndex(el => !el);
                if (emptyCellIndex === -1) return;
                this.cells.splice(emptyCellIndex, 1, item);
            } else {
                this.$router.push({
                    name: 'flavour',
                    params: {
                        code: item.CODE
                    },
                    query: {
                        back: this.$route.path
                    }
                });
            }
        },
        onClear() {
            if (this.isXS) scrollTo("box", -100);
            this.cells.forEach((el, index) => this.cells.splice(index, 1, null));
        },
        onRandomize() {
            this.onClear();
            if (this.isXS) scrollTo("box", -100);
            this.cells.forEach((el, index) => {
                setTimeout(() => {
                    this.cells.splice(index, 1, this.randomItem());
                }, index * 100);
            });
        },
        onCustomize(isCustom) {
            this.isCustom = isCustom;
            if (this.isXS && isCustom) {
                setTimeout(() => {
                    scrollTo("box", -80);
                }, 500);
            }
        },
        randomItem() {
            const r = Math.floor(Math.random() * this.flavours.length);
            return this.flavours[r];
        },
    },
    mounted() {
        if (this.item.BOX_SIZE) {
            const xy = this.item.BOX_SIZE.split("x");
            xy[0] = xy[0];
            xy[1] = xy[1].replace(/\D/, "");
            this.amount = Number(xy[0]) * Number(xy[1]);
            console.log("amount", this.amount);
            this.cells = new Array(this.amount).fill(null);

            this.isCustom = true;

            if (this.content) {
                this.isCustom = true;
                this.flavours.filter(el => this.content.some(id => id === el.ID)).forEach(f => {
                    this.onFlavourClick(f);
                });
            }
        }
    }
}
</script>

<style lang="scss">
.product-1kilo {
    /*&__sticky {

        @include up($md) {
            position: sticky;
            top: 200px;
        }
    }*/

    &__content {
        background: $gray-lightest;
        border-radius: 24px 24px 0 0;

        display: flex;
        align-content: center;
        flex-direction: column;
        //padding: 0 20px;

        position: fixed;
        z-index: 2;
        left: 50%;
        bottom: 0;
        width: 60%;
        min-width: 280px;
        height: 64px;
        transform: translateX(-50%);

        @include transition();

        @include up($md) {
            display: block;
            background: unset;
            border-radius: unset;
            align-content: flex-start;
            position: unset;
            height: unset;
            min-height: unset;
            width: unset;
            min-width: unset;
            transform: unset;
        }

        &.open {
            height: 65vh;
            width: 101%;
            //background: $white-alpha;

            @include up($md) {
                height: unset;
                width: unset;
            }
        }

        /*.flavour-types {
            display: none;
            @include up($md) {
                display: block;
            }
        }*/
    }

    &__sets {
        max-width: 320px;
        margin: 0 auto;
        display: flex;
        justify-content: center;

        @include up($sm) {
            max-width: 360px;
        }

        @include up($md) {
            max-width: 460px;
        }

        @include up($lg) {
            max-width: 640px;
        }

        a {
            display: flex;
            align-items: center;
            //flex-direction: column;
            //text-align: center;
            //width: 48%;

            /*@include up($md) {
                //flex-direction: row;
                //text-align: left;
            }*/

            &:hover {
                .product-1kilo__set-image {
                    border-color: $golden;
                }
            }

            &.selected {
                .product-1kilo__set-image {
                    border-color: $golden;
                }
            }

            &:not(:hover):not(.selected) {
                color: $gray !important;
            }

            h5 {
                font-size: $fs-h6;
                line-height: $lh-h6;

                @include up($lg) {
                    font-size: $fs-h5;
                    line-height: $lh-h5;
                }
            }
        }

        &.single {
            //padding-top: 200px;

            a {
                cursor: default;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    &__set {
        &-image {
            border: 2px solid transparent;
            border-radius: 50%;
            padding: 5px;
            width: 54px;
            height: 54px;
            @include transition();

            @include up($md) {
                width: 78px;
                height: 78px;
            }

            .v-image {
                width: 40px;
                height: 40px;

                @include up($md) {
                    width: 64px;
                    height: 64px;
                }
            }
        }

    }

    &__image {
        position: relative;

        //min-height: 220px;
        margin: 0 auto;
        display: flex;
        justify-content: center;

        @include up($lg) {
           // height: 127px;
        }

        .v-image {
            filter: brightness(1.01); // contrast(1.02);	// для исчезновения в jpg серого фона
            @include transition();

            &.closed {
                //transform: translateY(-2%);
                z-index: 1;

                @include up($md) {
                    &:hover {
                        opacity: 0;

                        ~ .open {
                            opacity: 1;
                        }
                    }
                }
            }

            /*&.open {
                transform: translateX(-50%) translateY(-52%);
            }*/
        }


    }

    &__frame {
        position: relative;
        width: 100%;
        //max-width: 280px;
        //max-height: 280px;

        @include up($sm) {
            max-width: unset;
            max-height: unset;
        }

        .spacer {
            opacity: 0;
        }

        .v-image {

            //height: 127px;
            width: 100%;
            //min-height: 220px;
            margin: 0 auto;
            display: flex;
            justify-content: center;

            //height: 100%;
            //mix-blend-mode: multiply;
            //background-blend-mode: exclusion;
            filter: brightness(1.01); // contrast(1.02);	// для исчезновения в jpg серого фона
            @include transition();

            &.closed {
                z-index: 1;
                cursor: pointer;

                &:hover {
                    opacity: 0;

                    ~ .open {
                        opacity: 1;
                    }
                }
            }

            &.open {
                opacity: 0;

                &:hover {
                    opacity: 1;
                }
            }

            &.box3x1m {

                @include up($md) {
                    width: 380px;
                    max-width: 380px;
                }

                @include up($lg) {
                    width: 440px;
                    max-width: 440px;
                }
            }
        }

        .box {
            width: 100%;
            height: 100%;
            border: 2px solid $golden-light;
            //max-width: 380px;
            //max-height: 380px;
            //min-width: 140px;
            //min-height: 140px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            //padding: 12px;

			@include up($sm) {
				border: 5px solid $golden-light;
			}

            .cell {
                position: relative;
                //padding: 12px;
                //box-sizing: border-box;
                border-right: 1px solid $golden-light;
                border-bottom: 1px solid $golden-light;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $white;

                &:hover {
                    svg {
                        opacity: 0.6;
                    }

                    .v-image {
                        opacity: 0.8;
                    }
                }

                svg {
                    @include transition();
                    fill: none;
                    //stroke: $golden;
                    width: 80%;
                    height: 80%;
                }

                .v-image {
                    position: relative;
                    z-index: 1;
                    max-width: 85%;
                    height: 85%;
                }
            }

            @include up($md) {
                //border: 7px solid $golden-light;
            }

            &.box2x1 {
                height: 135px;
                @include up($sm) {
                    height: 160px;
                }
                .cell {
                    width: 50.0%;
                    height: 100%;

                    &:nth-child(2n) {
                        border-right: none;
                    }

                    &:nth-last-child(-n+2) {
                        border-bottom: none;
                    }
                }
            }

            &.box2x2 {
                height: 270px;
                @include up($sm) {
                    height: 310px;
                }
                .cell {
                    width: 50.0%;
                    height: 50.0%;

                    &:nth-child(2n) {
                        border-right: none;
                    }

                    &:nth-last-child(-n+2) {
                        border-bottom: none;
                    }
                }
            }

            &.box2x3 {
                height: 330px;
                max-width: 225px;
                margin: 0 auto;

                .cell {
                    width: 50.0%;
                    height: 33.333%;

                    &:nth-child(2n) {
                        border-right: none;
                    }

                    &:nth-last-child(-n+2) {
                        border-bottom: none;
                    }
                }
            }

            &.box2x4 {
                margin: 0 auto;
                height: 400px;
                max-width: 225px;
                @include up($sm) {

                }
                .cell {
                    width: 50.0%;
                    height:25.0%;

                    &:nth-child(2n) {
                        border-right: none;
                    }

                    &:nth-last-child(-n+2) {
                        border-bottom: none;
                    }
                }
            }

            &.box2x6 {
                margin: 0 auto;
                height: 600px;
                max-width: 225px;

                .cell {
                    width: 50.0%;
                    height:16.666%;

                    &:nth-child(2n) {
                        border-right: none;
                    }

                    &:nth-last-child(-n+2) {
                        border-bottom: none;
                    }
                }
            }

            &.box1x3 {
                height: 320px;
                max-width: 120px;
                margin: 0 auto;

                .cell {
                    width: 100%;
                    height: 33.333%;

                    &:nth-child(3n) {
                        border-right: none;
                    }
                }
            }

            &.box1x6 {
                height: 580px;
                max-width: 115px;
                margin: 0 auto;

                .cell {
                    width: 100%;
                    height: 16.7%;

                    &:nth-child(3n) {
                        border-right: none;
                    }
                }
            }

            &.box20x1 {
                //height: 25px;
                //max-width: 115px;
                //max-width: 300px;
                margin: 0 auto;

				height: 22px;
				max-width: 320px;

                @include up($sm) {
                    height: 38px;
                    max-width: 600px;
                }

                @include up($md) {
                    height: 52px;
                    max-width: 900px;
                }
                @include up($lg) {
                    height: 65px;
                    max-width: 1200px;
                }

                .cell {
                    width: 5%;
                    height: 100%;
                }
            }

            &.box1x20 {
                height: 580px;
                max-width: 115px;
                margin: 0 auto;

                .cell {
                    width: 100%;
                    height: 16.7%;

                    &:nth-child(3n) {
                        border-right: none;
                    }
                }
            }

            &.box3x1m {
                padding: 16px 100px 16px 2px;
                border: 0;
                height: 85px;

                @include up($sm) {
                    padding: 25px 160px 25px 3px;
                    height: 140px;
                }

                @include up($md) {
                    padding: 23px 140px 23px 4px;
                    width: 380px;
                    height: 120px;

                }

                @include up($lg) {
                    padding: 26px 160px 26px 4px;
                    width: 440px;
                    height: 140px;
                }

                .cell {
                    width: 33.333%;
                    height: 100%;
                    border-bottom: none;

                    /*&:nth-child(3n) {
                        border-right: none;
                    }*/
                }
            }

            &.box3x3 {
                height: 320px;

                .cell {
                    width: 33.333%;
                    height: 33.333%;

                    &:nth-child(3n) {
                        border-right: none;
                    }

                    &:nth-last-child(-n+3) {
                        border-bottom: none;
                    }
                }
            }

            &.box3x4 {
                height: 420px;
                .cell {
                    width: 33.333%;
                    height:25.0%;

                    &:nth-child(3n) {
                        border-right: none;
                    }

                    &:nth-last-child(-n+2) {
                        border-bottom: none;
                    }
                }
            }

            &.box3x5 {
                height: 480px;
                .cell {
                    width: 33.333%;
                    height:20%;

                    &:nth-child(3n) {
                        //border-right: none;
                    }

                    &:nth-last-child(-n+2) {
                        //border-bottom: none;
                    }

                    &:nth-child(14) {
                        margin-right: auto;
                    }

                    &:last-child {
                        display: none;
                    }
                }
            }

            &.box4x6 {
                height: 460px;
                .cell {
                    width: 25%;
                    height: 16.71%;

                    &:nth-child(3n) {
                        //border-right: none;
                    }

                    &:nth-last-child(-n+2) {
                        //border-bottom: none;
                    }

                }
            }

            &.box4x9 {

                height: 700px;

                .cell {

                    width: 25%;
                    height:11.1111%;

                }
            }
        }
    }

    &__tools {
        @include transition();
        height: 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        opacity: 0;

        &.open {
            opacity: 1;
            height: 32px;
            margin-top: -20px;
        }

        a.disabled {
            cursor: default;
            opacity: 0.3;
        }
    }

    &__more {
        span {
            display: inline-flex;
            align-items: center;

            svg {
                width: 12px;
                //transform: rotate(180deg);
                margin-left: 4px;
            }
        }
    }

    /*&__more {
        span {
            display: inline-flex;
            align-items: center;

            svg {
                width: 12px;
                //transform: rotate(180deg);
                margin-left: 4px;
            }
        }
    }*/
}
</style>
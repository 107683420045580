<template>
	<section class="product-content">
		<v-container>
			<!--<v-row>
				<v-col cols="12">
					<p class="limited text-center mx-auto size2 mb-7">
						<transition name="fade" mode="out-in">
							<span v-if="isCustom" key="text1">Choose your flavours. Click over or drag a praline to
								the box on the left to create your set.</span>
							<span v-else key="text2">А perfect match for this box</span>
						</transition>
					</p>
				</v-col>
			</v-row>-->
			<v-row>
				<v-col>

					<v-row>
						<v-col cols="12" md="12" class="product-content__flavours">

							<flavour-types :flavours="flavours"
										   :selected="selected"
										   :types="types"
										   :wide="false"
										   @click="onItemClick"/>
						</v-col>

						<!--<transition name="fade">
							<v-col v-if="isContentVisible" cols="12" md="6" class="order-md-first transition product-content__content mt-10 mt-md-0">

								<div class="mx-auto product-content__box" :class="boxClass">
									<div v-for="(index) in amount">
										<transition name="fade-quick" mode="out-in">
											<v-img v-if="cells[index-1]"
												   :src="cells[index-1].PREVIEW_PICTURE"
												   @click="onBoxItemClick(index)"/>
											<svgicon v-else name="plus" :original="true"/>
										</transition>
									</div>
								</div>

								<div class="d-flex justify-center mt-8">

									<transition name="fade-quick" mode="out-in">
										<btn-primary v-if="false && isItemInBasket" class="large"
													 label="Check out"/>
										<btn-primary v-else class="large"
													 label="Add box to cart"
													 :loading="loading"
													 @click="onAddToBasket"/>
									</transition>
								</div>
							</v-col>
						</transition>-->
					</v-row>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script>
	import FlavourTypes from "@/components/flavour-types";
	import BtnPrimary from "@/components/btn-primary";

	export default {
		name: "product-content",
		components: {
			BtnPrimary,
			FlavourTypes
		},
		props: ["item", "isCustom", "flavours", "selected", "types", "loading"],
		data() {
			return {
				cells: null,
				amount: 0,
				typeIndex: 0,
				isContentVisible: false,
				//withFlavoursOnly: true
			}
		},
		/*watch: {
			isCustom: {
				immediate: true,
				handler(val, old) {
					if (val && !old) {
						this.withFlavoursOnly = false;
						setTimeout(() => {
							this.isContentVisible = true
						}, 300);
					} else if (!val && old) {
						this.isContentVisible = false;
						setTimeout(() => {
							this.withFlavoursOnly = true;
						}, 300);
					}
				}
			}
		},*/
		computed: {
			boxClass() {
				return "box" + this.item.BOX_SIZE;
			},
			isItemInBasket() {
				return this.$store.state.session?.basket?.find(el => Number(el.PRODUCT_ID) === Number(this.item.ID));
			}
		},
		methods: {
			onItemClick(item) {
				const emptyCellIndex = this.cells.findIndex(el => !el);
				if (emptyCellIndex === -1) return;
				this.cells.splice(emptyCellIndex, 1, item);
			},
			onBoxItemClick(index) {
				//index is 1-based
				let i = index - 1;
				this.cells.splice(i, 1, null);
			},
			onAddToBasket() {
				this.$emit("basket-add");
			}
		},
		mounted() {
			if ( this.item.BOX_SIZE ) {
				const xy = this.item.BOX_SIZE.split("x");
				this.amount = Number(xy[0]) * Number(xy[1]);
				this.cells = new Array(this.amount).fill(null);
			}
		}
	}
</script>

<style lang="scss">
	.product-content {

		/*&:not(.wide) {
			.product-content {

				&__content {
					width: 100%;
				}

				&__flavours {
					width: 100%;
				}

				@include up($md) {
					&__content {
						width: 50%;
					}

					&__flavours {
						width: 50%;
					}
				}
			}
		}

		&__content {
			width: 0;
		}

		&__flavours {
			width: 100%;
		}*/

		&__box {
			border: 5px solid $golden-light;
			max-width: 380px;
			max-height: 380px;
			min-width: 140px;
			min-height: 140px;
			display: flex;
			box-sizing: border-box;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;

			@include up($md) {
				border: 7px solid $golden-light;
			}

			svg {
				opacity: 0.6;
				/*stroke: $golden-light;
				rect {
					fill: $golden-light;
				}*/
			}

			& > div {
				padding: 12px;
				box-sizing: border-box;
				border-right: 1px solid $golden-light;
				border-bottom: 1px solid $golden-light;
				cursor: pointer;
				@include transition();

				&:hover {
					opacity: 0.8;
				}
			}

			&.box2x1 {
				width: 280px;
				height: 140px;

				@include up($md) {
					width: 380px;
					height: 190px;

					.v-image {
						max-width: 156px;
						height: 156px;
						margin: 0 auto;
					}
				}

				& > div {
					width: 50%;
					height: 100%;

					&:nth-child(2n) {
						border-right: none;
					}

					&:nth-last-child(-n+2) {
						border-bottom: none;
					}
				}
			}

			&.box2x2 {
				width: 280px;
				height: 280px;

				@include up($md) {
					width: 380px;
					height: 380px;

					.v-image {
						max-width: 156px;
						height: 156px;
						margin: 0 auto;
					}
				}

				& > div {
					width: 50%;
					height: 50%;

					&:nth-child(2n) {
						border-right: none;
					}

					&:nth-last-child(-n+2) {
						border-bottom: none;
					}
				}
			}

			&.box2x3 {
				width: 280px;
				height: 420px;

				.v-image {
					max-width: 100px;
					height: 100px;
					margin: 0 auto;
				}

				& > div {
					width: 50.0%;
					height: 33.333%;

					&:nth-child(2n) {
						border-right: none;
					}

					&:nth-last-child(-n+2) {
						border-bottom: none;
					}
				}
			}

			&.box3x3 {
				width: 420px;
				height: 420px;

				.v-image {
					max-width: 100px;
					height: 100px;
					margin: 0 auto;
				}

				& > div {
					width: 33.333%;
					height: 33.333%;

					&:nth-child(3n) {
						border-right: none;
					}

					&:nth-last-child(-n+3) {
						border-bottom: none;
					}
				}
			}

			svg {
				fill: none;
				//stroke: $golden;
				width: 100%;
				height: 100%;
			}
		}
	}
</style>